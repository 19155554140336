import React from 'react';
import styled from 'styled-components';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import {Carousel} from 'react-responsive-carousel';
import FixedRatioImage from './FixedRatioImage';
import {navigate} from 'gatsby';
import {message} from 'antd';

function ImageCarousel(props) {
  const {
    images,
    ratio = 4 / 6,
    thumbRatio = 1,
    itemExtraCss = '',
    showThumbs = true,
    showIndicators = false,
    autoPlay = false,
    interval = 300,
    showArrows = false,
    renderArrowPrev = () => null,
    renderArrowNext = () => null,
    carouselRef,
  } = props;
  return (
    <CarouselWrapper>
      <Carousel
        ref={carouselRef}
        axis={'horizontal'}
        infiniteLoop
        autoPlay={autoPlay}
        interval={interval}
        showIndicators={showIndicators}
        showStatus={false}
        showArrows={showArrows}
        showThumbs={showThumbs}
        renderArrowPrev={renderArrowPrev}
        renderArrowNext={renderArrowNext}
        thumbWidth={100}
        renderThumbs={(thumbs) =>
          images.map((image, idx) => <Thumbs key={idx} image={image.url} />)
        }>
        {images.map((image, idx) => (
          <FixedRatioImage
            key={idx}
            image={image.url}
            ratio={ratio}
            mode="cover"
            extraCss={itemExtraCss + `${image.link ? 'cursor: pointer;' : ''}`}
            onClick={async () => {
              try {
                const link = image.link;
                if (!link) {
                  return;
                }
                if (link.indexOf(window.location.origin) > -1) {
                  await navigate(
                    link.replace(new RegExp(`^${window.location.origin}`), ''),
                  );
                } else if (link.indexOf('https') === 0) {
                  window.open(link);
                } else {
                  await navigate(link);
                }
              } catch (e) {
                message.error('連結格式錯誤！');
              }
            }}
          />
        ))}
      </Carousel>
    </CarouselWrapper>
  );
}

function Thumbs(props) {
  const {image} = props;
  return <ThumbWrapper image={image} />;
}

const ThumbWrapper = styled.div`
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
`;

const CarouselWrapper = styled.div`
  /* overwrite library style */
  & .carousel {
    & > .thumbs-wrapper {
      margin: 10px 0px 0px;
      overflow-x: auto;

      & > .thumbs {
        padding: 0px;
        & > .thumb {
          height: 100px;
          cursor: pointer;
          border: none;
          margin-right: 10px;
        }
        & > .thumb.selected,
        & > .thumb.selected:hover {
          border: none;
        }
      }
    }

    .control-dots {
      text-align: right;
      padding-right: 80px;

      & > .dot {
        opacity: 1;
        background: #fff;
        box-shadow: 0 6px 14px 4px rgba(128, 128, 128, 0.32);
      }

      & > .dot.selected {
        background: #b79d7a;
        box-shadow: 0 6px 14px 4px #e0cfb8;
      }
    }
  }
  @media screen and (max-width: 768px) {
    & .carousel {
      & > .thumbs-wrapper {
        & > .thumbs {
          & > .thumb {
          }
          & > .thumb.selected,
          & > .thumb.selected:hover {
          }
        }
      }

      .control-dots {
        text-align: center;
        padding-right: 0;
      }
    }
  }
`;

export default ImageCarousel;
