import React, {useRef, useState} from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import ReactDelighters from '../../Components/ReactDelighters';
import ImageCarousel from '../../Components/ImageCarousel';
import FixedRatioImage from '../../Components/FixedRatioImage';
import imgHomeAbout from '../../images/home-about.png';
import Button from '../../Components/Button';
import {BREAK_POINTS, CATEGORY} from '../../Domain/Constants';
import useModal from '../../Hooks/useModal';
import {ChevronLeft} from '@styled-icons/material/ChevronLeft';
import {ChevronRight} from '@styled-icons/material/ChevronRight';
import RichTextPreview from '../../Components/RichTextPreview';
import {navigate} from 'gatsby';
import Category_1 from '../../images/category_1.jpg';
import Category_2 from '../../images/category_2.jpg';
import Category_3 from '../../images/category_3.jpg';
import NEWS_IMAGE from '../../images/news.jpeg';

function Arrow(props) {
  const {type, onClick = () => 0} = props;

  const commonStyle = {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 10,
    width: 40,
    height: 40,
    backgroundColor: 'rgba(255,255,255,0.5)',
    border: '1px solid #fff',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  };

  return type === 'prev' ? (
    <div onClick={onClick} style={{...commonStyle, left: 20}}>
      <ChevronLeft
        style={{position: 'absolute', left: 0, right: 0, top: 0, bottom: 0}}
        color={'#fff'}
      />
    </div>
  ) : (
    <div onClick={onClick} style={{...commonStyle, right: 20}}>
      <ChevronRight
        style={{position: 'absolute', left: 0, right: 0, top: 0, bottom: 0}}
        color={'#fff'}
      />
    </div>
  );
}

function Landing(props) {
  const [dimension] = useOutlet('dimension');
  const {modalElem, modalRef} = useModal();
  const carouselRef = useRef(null);
  const [actions] = useOutlet('actions');
  const [home, setHome] = React.useState(null);
  const {rwd} = dimension;

  React.useEffect(() => {
    async function fetchData() {
      try {
        actions.setLoading(true);
        let {results} = await actions.fetchDocuments('home', {}, ['-updated'], {
          limit: 1,
          offset: 0,
        });

        if (results.length === 0) {
          throw 'cannot get home data';
        }
        const home = results[0];
        setHome(home);
      } catch (ex) {
      } finally {
        actions.setLoading(false);
      }
    }

    fetchData();
  }, [actions, modalRef]);

  let _categories = [
    {
      // FIXME: should change back
      key: 'logistics',
      display: 'Dessert',
      image: Category_1,
      description: '宅配雪山肉桂捲、好點司康禮盒',
    },
    {
      key: 'dot',
      display: 'dot.dot. LIFE',
      image: Category_2,
      description: 'Designed by dot.dot. 美與好用的商品，讓日常變的更美好',
    },
    {
      key: 'online-menu',
      display: 'Online MENU',
      image: Category_3,
      description: '花蓮實體店線上菜單',
    },
  ];

  const getCarouselAssets = (home) => {
    if (!home) {
      return [];
    }
    if (rwd === 'desktop') {
      return home.banners.map((b) => ({
        url: b.expected_url,
        link: b.extra_data?.link || '',
      }));
    } else {
      return home.mobileBanners.map((b) => ({
        url: b.expected_url,
        link: b.extra_data?.link || '',
      }));
    }
  };

  return (
    <ReactDelighters>
      <Wrapper>
        <ImageCarousel
          carouselRef={carouselRef}
          autoPlay={false}
          showThumbs={false}
          showIndicators={true}
          showArrows={true}
          images={getCarouselAssets(home)}
          ratio={rwd === 'desktop' ? 728 / 1320 : 1}
          renderArrowPrev={() => (
            <Arrow
              onClick={() => {
                carouselRef.current.onClickPrev();
              }}
              type="prev"
            />
          )}
          renderArrowNext={() => (
            <Arrow
              onClick={() => {
                carouselRef.current.onClickNext();
              }}
              type="next"
            />
          )}
        />

        <section className="three">
          {_categories.map((c, idx) => (
            <div
              onClick={(e) => {
                e.stopPropagation();
                if (c.key === 'online-menu') {
                  return navigate(`/online-menu`);
                }
                return navigate(`/products?cat=${c.key}`);
              }}
              style={{cursor: 'pointer'}}
              className="item"
              key={idx}>
              <FixedRatioImage
                width="100%"
                mode="cover"
                ratio={250 / 390}
                image={c.image}
                style={{marginBottom: 20}}
              />
              <div className="title">{c.display}</div>
              <div className="subtitle">{c.description}</div>
              <Button
                type="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  if (c.key === 'online-menu') {
                    return navigate(`/online-menu`);
                  }
                  return navigate(`/products?cat=${c.key}`);
                }}>
                Read more
              </Button>
            </div>
          ))}
        </section>

        <section className="two">
          <FixedRatioImage
            width={rwd === 'desktop' ? 800 : '100%'}
            mode="cover"
            ratio={448 / 800}
            image={imgHomeAbout}
            style={{marginBottom: 50}}
          />
          <div className="title">dot.dot. Bakery & Cafe</div>
          <div className="subtitle">
            {`Time ages our hearts, Desserts bring our hearts together,\nWith the sweet of love Everyone becomes a poet.`}
          </div>
          <Button type="primary" onClick={() => navigate('/about')}>
            Read more
          </Button>
        </section>

        <section className="one">
          <FixedRatioImage
            width={rwd === 'desktop' ? 800 : '100%'}
            mode="cover"
            ratio={165 / 500}
            image={NEWS_IMAGE}
            style={{marginBottom: 50, cursor: 'pointer'}}
            onClick={async () => {
              await navigate('/news');
            }}
          />
        </section>

        {modalElem}
      </Wrapper>
    </ReactDelighters>
  );
}

const Wrapper = styled.div`
  padding: var(--topNavBarHeight) 40px 0 40px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  color: #000000;

  & > .description {
    align-self: center;
    max-width: 250px;
    font-weight: bold;
    font-size: 14px;
    margin: 80px 0;
    text-align: center;
  }

  & > section.one {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 100px;

    & > .title {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 20px;
    }
  }

  & > section.two {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 100px;

    & > .title {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 30px;
    }

    & > .subtitle {
      max-width: 600px;
      text-align: center;
      font-size: 14px;
      line-height: 25px;
      margin-bottom: 40px;
      white-space: pre-line;
    }
  }

  & > section.three {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    margin: 100px auto;
    flex-wrap: wrap;

    & > .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: calc(100% / 3 - 30px);
      margin: 0 10px;

      & > .title {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 20px;
      }

      & > .subtitle {
        max-width: 370px;
        text-align: center;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 40px;
        flex: 1;
      }
    }
  }

  @media screen and (max-width: ${BREAK_POINTS.tablet}px) {
    padding: var(--topNavBarHeight) 0 0 0;
    & > section.three {
      & > .item {
        width: 100%;
        margin: 10px auto 10px auto;
      }
    }
  }
`;

export default Landing;
